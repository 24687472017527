import request from '@/utils/request'

// 查询拓客角色列表
export function listRole(query) {
  return request({
    url: '/member/role/list',
    method: 'get',
    params: query
  })
}

// 查询拓客角色列表
export function getExpandRole(query) {
  return request({
    url: '/member/role/getExpandRole',
    method: 'get',
    params: query
  })
}
// 查询拓客角色详细
export function getRole(id) {
  return request({
    url: '/member/role/' + id,
    method: 'get'
  })
}

// 新增拓客角色
export function addRole(data) {
  return request({
    url: '/member/role',
    method: 'post',
    data: data
  })
}

// 修改拓客角色
export function updateRole(data) {
  return request({
    url: '/member/role',
    method: 'put',
    data: data
  })
}

// 删除拓客角色
export function delRole(id) {
  return request({
    url: '/member/role/' + id,
    method: 'delete'
  })
}

export function roleRule(data) {
  return request({
    url: '/expand/roleRule',
    method: 'post',
    data: data
  })
}


